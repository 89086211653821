import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";

import { getCategory } from "../../store/category/category.action";
import {
  createSubCategory,
  updateSubCategory,
  createServiceSubCategory,
  updateServiceSubCategory,
} from "../../store/subCategory/subCategory.action";

const SubCategoryDialoge = (props) => {
  const { dialogueData, extraData } = useSelector((state) => state.dialogue);

  console.log("dialogueData", dialogueData);

  const { category } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const [mongoId, setMongoId] = useState(0);
  const [name, setName] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [categoryType, setCategoryType] = useState(extraData);
  const [error, setError] = useState({
    name: "",
    image: "",
    categoryType: "",
  });

  console.log("categoryType", categoryType);

  console.log("dialogueData::::", dialogueData?.category?.name);

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setName(dialogueData?.name);
    setImagePath(dialogueData?.image);

    console.log("dialogueData?.category",dialogueData?.category)
    if (mongoId) {
      setCategoryType(dialogueData?.category?.name);
    } else {
      setCategoryType(extraData?.data?.name);
    }
  }, [dialogueData, extraData]);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
  };
  console.log("extraData", extraData);
  const handleSubmit = (e) => {
    if (!name || !image) {
      let error = {};
      if (!name) error.name = "Name is Required !";

      if (!image?.length === 0 || !imagePath)
        error.image = "Image is required!";

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      if(dialogueData){
      formData.append("category", dialogueData?.category?._id);

      } else{

          formData.append("category", extraData?.id);
      }

      if (mongoId) {
        dispatch(updateServiceSubCategory(formData, mongoId));
      } else {
        dispatch(createServiceSubCategory(formData, extraData));
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Sub Category</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
          <div className="col-12">
              <Input
                label={`Service Categoty`}
                id={`name`}
                type={`text`}
                readOnly={true}
                disabled={true}
                value={dialogueData ? dialogueData?.category?.name : extraData?.data?.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                label={`Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>
            <div className="col-12">
              <Input
                label={`Image`}
                id={`image`}
                type={`file`}
                accept={`image/*`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.image && error.image}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="banner"
                    draggable="false"
                    width={100}
                    className="m-0"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={`Submit`}
              btnColor={`btnBlackPrime text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              newClass={`me-2`}
              onClick={handleSubmit}
            />
            <Button
              btnName={`Close`}
              btnColor={`bg-danger text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getCategory,
  createSubCategory,
  updateSubCategory,
})(SubCategoryDialoge);
