import axios from "axios";
import * as ActionType from "./service.type";
import { apiInstanceFetch } from "../../../util/api";
import { setToast } from "../../../util/toast";

export const getservice = () => (dispatch) => {
  apiInstanceFetch
    .get(`serviceCategory/getServiceCategory`)
    .then((res) => {
      //
      dispatch({
        type: ActionType.GET_SERVICE,
        payload: res.service,
      });
    })
    .catch((error) => console.error(error));
};

// CREATE_service
export const createservice = (formData) => (dispatch) => {
  axios
    .post(`serviceCategory/create`, formData)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "service Create successfully");
        dispatch({
          type: ActionType.CREATE_SERVICE,
          payload: res.data.service,
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// UPDATE_service

export const updateservice = (formData, id) => (dispatch) => {
  axios
    .patch(`serviceCategory/update?serviceCategoryId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_SERVICE,
          payload: { data: res.data.service, id },
        });
        setToast("success", "service update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// DELETE_service

export const deleteservice = (id) => (dispatch) => {
  axios
    .delete(`serviceCategory/delete?serviceId=${id}&productType=${2}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_SERVICE,
          payload: id,
        });
        setToast("success", "service Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const getFakeService = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`product/getFakeProducts?start=${start}&limit=${limit}&productType=${2}`)
    .then((res) => {
      
      dispatch({
        type: ActionType.GET_FAKE_SERVICE,
        payload: res,
        totalProduct: res.totalProducts,
      });
    })
    .catch((error) => console.error(error));
};

export const updateProductService =
(formData, id, sellerId, productCode , productType) => (dispatch) => {
  axios
    .patch(
      `product/update?productId=${id}&sellerId=${sellerId}&productCode=${productCode}&productType=${productType}`,
      formData
    )
    .then((res) => {
      if (res.data.status) {
        
        dispatch({
          type: ActionType.UPDATE_PRODUCT_SERVICE,
          payload: { data: res.data.product, id },
        });
        setToast("success", "Fake Service update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const createFakeService = (formData) => (dispatch) => {
  axios
    .post(`product/createProductByAdmin`, formData)
    .then((res) => {
      if (res.data.status) {
        
        dispatch({
          type: ActionType.CREATE_FAKE_SERVICE,
          payload: res.data.product,
        });
        setToast("success", "Fake Service Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};


export const deleteFakeService = (id) => (dispatch) => {
  axios
    .delete(`product/delete?productId=${id}&productType=${2}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_FAKE_SERVICE,
          payload: id,
        });
        setToast("success", "Fake Service Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};