import React, { useState } from "react";
import Button from "../../extra/Button";
import ProductDialog from "../Action/ProductDialog";
import ServiceDialog from "../Service/ServiceDialog";
import AddService from "./AddService";
import AppProduct from "./AddProduct";

export const ActionSetting = () => {
  const [status, setStatus] = useState("Product");
  return (
    <>
    <div className="row mt-2">
      <div className="col-6 d-flex" id="">
        <Button
          newClass={`themeFont mx-2 text-light ${
            status === "Product" ? "create-btn" : "default-btn"
          }`}
          btnName={`Product`}
          style={{
            borderRadius: "5px",
            width: "auto",

            cursor: "pointer",
          }}
          onClick={() => setStatus("Product")}
        />
        <Button
          newClass={`themeFont mx-2 text-light ${
            status === "Service" ? "update-btn" : "default-btn"
          }`}
          btnName={`Service`}
          style={{
            borderRadius: "5px",
            width: "auto",

            cursor: "pointer",
          }}
          onClick={() => setStatus("Service")}
        />
      </div>
    </div>
    <div>
      {status === "Product" && <AddService /> }
      {status === "Service" && <AppProduct /> }


    </div>
  </>
  )
};
