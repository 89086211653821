// GET_SERVICE
export const GET_SERVICE = "GET_SERVICE";


// CREATE_SERVICE
export const CREATE_SERVICE = "CREATE_SERVICE";
// EDIT_SERVICE

export const UPDATE_SERVICE = "UPDATE_SERVICE";

// DELETE_SERVICE
export const GET_FAKE_SERVICE = "GET_FAKE_SERVICE"
export const DELETE_SERVICE = "DELETE_SERVICE"

export const UPDATE_PRODUCT_SERVICE = "UPDATE_PRODUCT_SERVICE"

export const CREATE_FAKE_SERVICE = "CREATE_FAKE_SERVICE"

export const DELETE_FAKE_SERVICE = "DELETE_FAKE_SERVICE"
