// GET_FAKE_SELLER
export const GET_FAKE_SELLER = "GET_FAKE_SELLER";

export const GET_PRODUCT_SERVICE = "GET_PRODUCT_SERVICE"

// CREATE_FAKE_SELLER
export const CREATE_FAKE_SELLER = "CREATE_FAKE_SELLER";

// UPDATE_FAKE_SELLER
export const UPDATE_FAKE_SELLER = "UPDATE_FAKE_SELLER";

// isLive fake seller
export const ISLIVE_FAKE_SELLER = "ISLIVE_FAKE_SELLER";

// DELETE_FAKE_SELLER
export const DELETE_FAKE_SELLER = "DELETE_FAKE_SELLER";


// GET_FAKE_SELLER_NAME
export const GET_FAKE_SELLER_NAME = "GET_FAKE_SELLER_NAME";

export const IS_LIVE_SELLER = "IS_LIVE_SELLER";
export const IS_OFFLINE = "IS_OFFLINE";
