import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { connect, useDispatch, useSelector } from "react-redux";

import {
    createWithdraw,
    updateWithdraw,
} from "../../store/withdraw/withdraw.action";
import { createAction, getData, getSetting } from "../../store/setting/setting.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { OPEN_ACTION_DIALOGUE } from "../../store/setting/setting.type";

const ProductDialog = (props) => {
    const { dialogueData } = useSelector((state) => state.dialogue);
    const { setting } = useSelector((state) => state.setting);
    const dispatch = useDispatch();
    const { dialogue, dialogueType } = useSelector(
        (state) => state.dialogue
    );


    console.log("dialogueType", dialogueType)

    const { productActionData, dialogue1, dialogueType1  } = useSelector((state) => state.setting)

    console.log("setting", setting)

    const [mongoId, setMongoId] = useState("");
    const [detail, setDetail] = useState("");
    const [addDetail, setAddDetail] = useState([]);
    const [data , setData] = useState([]);

    console.log("addDetails", addDetail)

    const [error, setError] = useState({
        addDetail: "",
    });

    useEffect(() => {
        dispatch(getSetting());
    }, [dispatch])



    useEffect(() => {
        setMongoId(dialogueData?._id);
        setAddDetail(dialogueData?.details);
    }, [dialogueData]);

    const addCountryList = (e) => {
        e.preventDefault();
        setAddDetail((old) => {
            if (!Array.isArray(old)) {
                old = [];
            }
            return [...old, detail];
        });
        setDetail("");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addCountryList(event);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!detail) {
            let error = {};
            if (!detail) error.detail = "Details is required!";

            setError({ ...error });
        } else {
            const data = {
                productAction: detail
            }
            dispatch(createAction(data ,1));
            dispatch({ type: CLOSE_DIALOGUE });
        }
    };

    return (
        <div className="mainDialogue fade-in">
            <div className="Dialogue">
                <div className="dialogueHeader">
                    <div className="headerTitle fw-bold">Product</div>
                    <div
                        className="closeBtn "
                        onClick={() => {
                            dispatch({ type: CLOSE_DIALOGUE });
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>

             
                <div className="dialogueMain">

                    <div className="row">
                        <div className="col-12 w-100">
                            <div>
                                <div className="form-group mb-2">
                                </div>
                                <div className="d-flex align-items-center">
                                    <Input
                                        type={`text`}
                                        label={`Enter Detail`}
                                        value={detail}
                                        newClass={detail ? "col-11" : "col-12"}
                                        errorMessage={error.detail && error.detail}
                                        placeholder={`Enter detail`}
                                        onChange={(e) => {
                                            setDetail(e.target.value);
                                            if (!e.target.value) {
                                                return setError({
                                                    ...error,
                                                    detail: `Details Is Required`,
                                                });
                                            } else {
                                                return setError({
                                                    ...error,
                                                    detail: "",
                                                });
                                            }
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="dialogueFooter">
                    <div className="dialogueBtn">
                        <Button
                            btnName={`Submit`}
                            btnColor={`btnBlackPrime text-white`}
                            style={{ borderRadius: "5px", width: "80px" }}
                            newClass={`me-2`}
                            onClick={handleSubmit}
                        />
                        <Button
                            btnName={`Close`}
                            btnColor={`bg-danger text-white`}
                            style={{ borderRadius: "5px", width: "80px" }}
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { createWithdraw, updateWithdraw })(
    ProductDialog
);
