import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { connect, useDispatch, useSelector } from "react-redux";

import {
    createAttribute,
    updateAttribute,
} from "../../store/attribute/attribute.action";
import { getProductOrService, selectProductsAndSetSellerLive } from "../../store/fake Seller/fakeSeller.action";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    // return {
    //   fontWeight:
    //     personName(name) === -1
    //       ? theme.typography.fontWeightRegular
    //       : theme.typography.fontWeightMedium,
    // };
}

const LiveSellerDialogue = () => {
    const { dialogueData } = useSelector((state) => state.dialogue);
    const { fakeProductOrService } = useSelector((state) => state.fakeSeller)
    const [mongoId, setMongoId] = useState("");
    const [name, setName] = useState();
    const [detail, setDetail] = useState("");
    const [addDetail, setAddDetail] = useState([]);
    const [category, setService] = useState("product");
    const dispatch = useDispatch();
    const [filteredProducts, setFilteredProducts] = useState([]);
    const theme = useTheme();
    const [personNames, setPersonNames] = useState([
        {
            name: "",
            value: [],
        },
    ]);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedId, setSelectedId] = useState([]);


    console.log("selectedProducts", selectedProducts)




    const [selectErrors, setSelectErrors] = useState(
        Array(fakeProductOrService.length).fill("")
    );


    const type = category === "product" ? 1 : 2

    console.log("type", type)

    const [error, setError] = useState({
        name: "",
        detail: "",
        selectedProducts: ""
    });

    useEffect(() => {
        dispatch(getProductOrService(type, dialogueData?._id))
    }, [type, dispatch])

    useEffect(() => {
        const filtered = fakeProductOrService.filter((item) => item.type === type);
        setFilteredProducts(filtered);
        setSelectedProducts([]); // Reset selected products when type changes
        setSelectedId([]); // Reset selected IDs when type changes
    }, [type]);


    useEffect(() => {
        setMongoId(dialogueData?._id);
        setName(dialogueData?.firstName + dialogueData?.lastName);
        setAddDetail(dialogueData?.value);
    }, [dialogueData]);

    const addCountryList = (e) => {
        e?.preventDefault();
        setAddDetail((old) => {
            if (!Array.isArray(old)) {
                old = [];
            }
            return [...old, detail.charAt(0).toUpperCase() + detail.slice(1)]; // Capitalize the detail before adding
        });
        setDetail("");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addCountryList(event);
        }
    };

    const onRemove = (id) => {
        setAddDetail((old) => {
            if (!Array.isArray(old)) {
                old = [];
            }
            return old.filter((array, index) => {
                return index !== id;
            });
        });
    };

    const handleProductChange = (event) => {
        const {
            target: { value },
        } = event;

        const selectedValues = typeof value === "string" ? value.split(",") : value;

        console.log("selectedValues", selectedValues)

        setSelectedProducts(selectedValues);
        const selectedIds = selectedValues
            .map((productName) => {
                const product = fakeProductOrService.find(
                    (item) => item.productName === productName
                );
                return product ? product._id : null;
            })
            .filter((id) => id !== null);

        console.log("Selected IDs:", selectedIds);
        setSelectedId(selectedIds);
    };

    // const handlePersonNameChange = (index, selectedValues, attributeName) => {
    //     const updatedPersonNames = [...personNames];
    //     updatedPersonNames[index] = {
    //         ...updatedPersonNames[index],
    //         value: selectedValues,
    //         name: attributeName,
    //     };
    //     setPersonNames(updatedPersonNames);
    //     const newErrors = [...selectErrors];

    //     setSelectErrors(newErrors);
    //     if (personNames[0]?.value?.length === 0 && index === 0) {
    //         return setError({
    //             ...error,
    //             personNames: "Attributes is Required !",
    //         });
    //     } else {
    //         setError({
    //             ...error,
    //             personNames: "",
    //         });
    //     }
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name || detail || !category || !selectedProducts) {
            let error = {};
            if (!name) error.name = "Name is required!";
            if (!category) error.category = "Category is required!"
            if (!detail) error.detail = "Details are required!";
            if (!selectedProducts) error.selectedProducts = "Please Select a Product"

            setError({ ...error });
        } else {


            const data = {
                sellerId: dialogueData?._id,
                productIds: selectedId,
            };

            dispatch(selectProductsAndSetSellerLive(data));
        }
        dispatch({ type: CLOSE_DIALOGUE });
    }


    return (
        <div className="mainDialogue fade-in">
            <div className="Dialogue">
                <div className="dialogueHeader">
                    <div className="headerTitle fw-bold">Live Seller</div>
                    <div
                        className="closeBtn "
                        onClick={() => {
                            dispatch({ type: CLOSE_DIALOGUE });
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className="dialogueMain">
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label={`Name`}
                                id={`name`}
                                type={`text`}
                                value={name}
                                disabled={true} // Use the correct prop for disabling
                                style={{ cursor: "not-allowed" }} // Add the style to prevent cursor
                            />
                        </div>


                        <div className="col-md-6 col-12">
                            <div className="prime-input mb-2">
                                <label className="mb-3 select_product"> Select Category</label>
                            </div>
                            <div className="d-flex align-items-center">
                                <Input
                                    label={`Product`}
                                    name={`category`}
                                    id={`product`}
                                    type={`radio`}
                                    value={`product`}
                                    checked={
                                        (category == "product" || category == "Product") && true
                                    }
                                   
                                    newClass={`me-3`}
                                    onChange={(e) => {
                                        setService(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                category: `Category Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                category: "",
                                            });
                                        }
                                    }}
                                />
                                <Input
                                    label={`Service`}
                                    name={`category`}
                                    id={`service`}
                                    type={`radio`}
                                    value={`service`}
                                    checked={
                                        (category == "service" || category == "Service") && true
                                    }
                                    onChange={(e) => {
                                        setService(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                category: `Service Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                category: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {error.category && (
                                <>
                                    <p className="errorMessage">{error.category}</p>
                                </>
                            )}
                        </div>
                        <label className="select_product">{`Select ${category}`}</label>

                        <div className="col-md-12 col-12"
                            style={{
                                paddingRight: "20px",
                                paddingLeft: "0px"
                            }}
                        >
                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="product-select-label">{`Select ${category}`}</InputLabel>
                                <Select
                                    labelId="product-select-label"
                                    id="product-select"
                                    multiple
                                    value={selectedProducts}
                                    onChange={handleProductChange}
                                    input={<OutlinedInput label="Select Products" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {fakeProductOrService.map((data) => (
                                        <MenuItem key={data._id} value={data.productName}>
                                            {data.productName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error.selectedProducts && (
                                    <>
                                        <p className="errorMessage">{error.selectedProducts}</p>
                                    </>
                                )}
                            </FormControl>
                        </div>

                        {/* <div className="col-12">
                            <Input
                                label={`Name`}
                                id={`name`}
                                type={`text`}
                                value={name}
                                disable={true}
                                errorMessage={error.name && error.name}
                                onChange={(e) => {
                                    const capitalizedValue =
                                        e.target.value.charAt(0).toUpperCase() +
                                        e.target.value.slice(1);
                                    setName(capitalizedValue); // Set the capitalized value as the name
                                    if (!capitalizedValue) {
                                        setError({
                                            ...error,
                                            name: `Name Is Required`,
                                        });
                                    } else {
                                        setError({
                                            ...error,
                                            name: "",
                                        });
                                    }
                                }}
                            />
                        </div> */}
                    </div>
                    {/* <div className="row">
                        <div className="col-12 w-100">
                            <div>
                                <div className="d-flex align-items-center">
                                    {name === "Colors" ? (
                                        <>
                                            <div
                                                className={`prime-input ${detail ? "col-11" : "col-12"
                                                    } `}
                                            >
                                                <label>Value</label>
                                                <input
                                                    type="color"
                                                    className={`form-input text-capitalize`}
                                                    required=""
                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                    value={detail}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={(e) => {
                                                        setDetail(e.target.value);
                                                        if (!e.target.value) {
                                                            return setError({
                                                                ...error,
                                                                detail: "Details is Required!",
                                                            });
                                                        } else {
                                                            return setError({
                                                                ...error,
                                                                detail: "",
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Input
                                                type={`text`}
                                                label={`Value`}
                                                value={detail}
                                                errorMessage={error.detail && error.detail}
                                                newClass={`${detail ? "col-11" : "col-12"
                                                    } text-capitalize`}
                                                placeholder={`Enter detail`}
                                                onChange={(e) => {
                                                    setDetail(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...error,
                                                            detail: `Details Is Required`,
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...error,
                                                            detail: "",
                                                        });
                                                    }
                                                }}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </>
                                    )}
                                    {detail !== "" && (
                                        <div
                                            className=" px-3 text-white d-flex align-items-center justify-content-center"
                                            style={{

                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                backgroundColor: "#664dc9",
                                                padding: "6px 0px",
                                                marginTop: `${name === "Colors" ? "12px" : "0px"}`
                                            }}
                                            onClick={addCountryList}
                                        >
                                            <span>ADD</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-group mb-2">

                            </div>
                            <div className="mb-2">
                                <div
                                    className="displayCountry form-control border p-3"
                                    style={{ overflow: "auto" }}
                                >
                                    {addDetail?.map((item, id) => {
                                        return (
                                            <>
                                                <span
                                                    className="ms-1 my-1 text-capitalize"
                                                    style={{
                                                        backgroundColor: " #b93160",
                                                        padding: "5px",
                                                        color: " #fff",
                                                        borderRadius: "5px",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {item}
                                                    <i
                                                        className="fa-solid fa-circle-xmark ms-2 my-2"
                                                        onClick={() => {
                                                            onRemove(id);
                                                        }}
                                                    ></i>
                                                </span>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="dialogueFooter">
                    <div className="dialogueBtn">
                        {!mongoId ? (
                            <>
                                <Button
                                    btnName={`Submit`}
                                    btnColor={`btnBlackPrime text-white`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    newClass={`me-2`}
                                    onClick={handleSubmit}
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    btnName={`Submit`}
                                    btnColor={`btnBlackPrime text-white`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    newClass={`me-2`}
                                    onClick={handleSubmit}
                                />
                            </>
                        )}
                        <Button
                            btnName={`Close`}
                            btnColor={`bg-danger text-white`}
                            style={{ borderRadius: "5px", width: "80px" }}
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { createAttribute, updateAttribute })(
    LiveSellerDialogue
);
