
import * as ActionType from "./setting.type";
const initialState = {
  setting: [],
  productActionData: [],
  dialogue1: false,
  dialogueType1: "",
  dialogueData1: null,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SETTING:
      return {
        ...state,
        setting: action.payload,
      };

      case ActionType.GET_DATA:
        return {
          ...state,
          productActionData: action.payload,
        };
    case ActionType.UPDATE_SETTING:
      return {
        ...state,
      };

     
    case ActionType.CREATE_ACTION:
      let data = Array.isArray(state.productActionData) 
      ? [...state.productActionData, action.payload]  // If it's already an array, push to it
      : [state.productActionData, action.payload];  // If it's an object, convert to array with the new payload

  return {
      ...state,
      productActionData: data, // Update the state with the new array
  };

    case ActionType.GET_DAILY_REWARD:
      console.log('action?.paylaod', action?.paylaod?.data)
      return {
        ...state,
        dailyReward: action.payload,
      };

    case ActionType.ADD_DAILY_REWARD:
      let dailyRewardAdd = [...state.dailyReward];
      dailyRewardAdd.unshift(action.payload);
      return {
        ...state,
        dailyReward: dailyRewardAdd,
      };

    case ActionType.EDIT_DAILY_REWARD:
      const updatedDailyReward = state.dailyReward?.map((item) => {
        if (item?._id === action.payload.id) {
          return {
            ...item,
            ...action.payload.data,
          };
        }
        return item;
      });
      return {
        ...state,
        dailyReward: updatedDailyReward,
      };

    case ActionType.DELETE_DAILY_REWARD:
      return {
        ...state,
        dailyReward: state.dailyReward.filter(
          (data) => !action?.payload.includes(data?._id)
        ),
      };

      case ActionType.DELETE_PRODUCT_DATA:
      return {
        ...state,
        productActionData: state.productActionData.filter(
          (data) => !action?.payload.includes(data?._id)
        ),
      };

        case ActionType.OPEN_ACTION_DIALOGUE:
            return {
              ...state,
              dialogue1: true,
              dialogueType1: action.payload.type || "",
              dialogueData1: action.payload.data || null,
            };
          case ActionType.CLOSE_ACTION_DIALOGUE:
            return {
              ...state,
              dialogue1: false,
              dialogueType1: "",
              dialogueData1: null,
            };
    //Handle Update Switch Value
    case ActionType.HANDLE_TOGGLE_SWITCH:

      return {
        ...state,
        setting: action.payload.setting,
      };
    default:
      return state;
  }
};
