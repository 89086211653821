//Get Setting
export const GET_SETTING = "GET_SETTING";

//Update Setting
export const UPDATE_SETTING = "UPDATE_SETTING";


//Toggle Switch
export const HANDLE_TOGGLE_SWITCH = "HANDLE_TOGGLE_SWITCH";

export const CREATE_ACTION = "CREATE_ACTION";


export const GET_DAILY_REWARD = "GET_DAILY_REWARD";
export const GET_DATA = "GET_DATA";

export const ADD_DAILY_REWARD = "ADD_DAILY_REWARD";
export const EDIT_DAILY_REWARD = "EDIT_DAILY_REWARD";
export const DELETE_DAILY_REWARD = "DELETE_DAILY_REWARD";
export const DELETE_PRODUCT_DATA = "DELETE_PRODUCT_DATA";


export const CLOSE_ACTION_DIALOGUE = "CLOSE_ACTION_DIALOGUE"
export const OPEN_ACTION_DIALOGUE = "OPEN_ACTION_DIALOGUE"
