import * as ActionType from "./service.type";

const initialState = {
  service: [],
  fakeService: []
};

export const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
        case ActionType.GET_FAKE_SERVICE:
          return {
            ...state,
            fakeService: action.payload.product,
            totalProduct: action.payload.totalProducts,
          };
    case ActionType.GET_SERVICE:
      return {
        ...state,
        service: action.payload,
      };

    case ActionType.UPDATE_SERVICE:
      return {
        ...state,
        service: state.service.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };

    case ActionType.UPDATE_PRODUCT_SERVICE:
      
      return {
        ...state,
        fakeService: state.fakeService.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };

    case ActionType.CREATE_FAKE_SERVICE:
      let data = [...state.fakeService];
      data.unshift(action.payload);
      return {
        ...state,
        fakeService: data,
      };
    case ActionType.DELETE_SERVICE:
      return {
        ...state,
        service: state.service.filter(
          (data) => data._id !== action.payload && data
        ),
      };

    case ActionType.DELETE_FAKE_SERVICE:
      return {
        ...state,
        fakeService: state.fakeService.filter(
          (data) => data._id !== action.payload && data
        ),
      };

    case ActionType.CREATE_SERVICE:
      return {
        ...state,
        service: [action.payload, ...state.service], // Create a new array with the new service first
      };

    default:
      return state;
  }
};
